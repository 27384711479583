<template>
  <div class="message-row pb-5">
    <template v-if="message.params.data">
      <img class="UIServerText-avatar" :src="systemAvatar" />

      <div class="UIServerText-container">
        <div class="UIServerText-date">{{ message.createdAt }}</div>

        <div class="UIServerText">{{ message.params.data }}</div>
      </div>
    </template>

    <div style="clear: both"></div>

    <div class="q-carousel mt-2 mb-2">
      <Carousel
        :perPage="1"
        :scrollPerPage="true"
        :paginationEnabled="true"
        :navigationEnabled="true"
        navigationPrevLabel="<i class='fas fa-angle-left'></i>"
        navigationNextLabel="<i class='fas fa-angle-right'></i>">
        <Slide v-for="(item, i) in message.params.items" :key="i">
          <v-card class="fill-height layout column mx-3">
            <div class="">
              <v-img :src="item.src" aspect-ratio="2" />
            </div>

            <v-divider></v-divider>

            <v-card-title>
              <div>
                <h3 class="headline mb-3">{{ item.title }}</h3>
                <div>{{ item.text }}</div>
              </div>
            </v-card-title>

            <template v-if="false && item.actions">
              <v-spacer></v-spacer>
              <v-divider></v-divider>

              <div class="layout wrap justify-start pa-1" style="flex-grow: 0">
                <v-btn
                  v-for="(action, i) in item.actions"
                  :key="i"
                  @click="action.f"
                  depressed
                  class="ma-1">
                  {{ action.title }}
                </v-btn>
              </div>
            </template>
          </v-card>
        </Slide>
      </Carousel>
    </div>

    <div style="clear: both"></div>
  </div>
</template>

<script>
  import { Carousel, Slide } from 'vue-carousel';
  import { mapGetters } from 'vuex';
  // import slideImg from '@/assets/back_new.png';

  export default {
    name: 'UIServerCarousel',
    components: {
      Carousel,
      Slide,
    },
    props: {
      message: Object,
    },
    computed: {
      ...mapGetters('theme', ['systemAvatar']),
    },
  };
</script>

<style lang="stylus" scoped>
  .message-row {
  	clear: both;
  }

  .UIServerText-avatar {
  	float: left;
  	margin-left: 16px;
  	margin-right: 10px;
  	margin-top: 8px;
  	margin-bottom: 8px;
  	width: 44px;
  	height: 44px;
  }

  .UIServerText-container {
  	float: left;
  	width: calc(100% - 140px);
  }

  .UIServerText-date {
  	text-align: left;
  	margin: 4px;
  	font-size: 11px;
  	color: #92A0A6;
  }

  .UIServerText {
  	background: linear-gradient(180deg, rgb(233, 248, 253), rgb(219, 240, 251));
  	border-radius: 4px 44px 44px 44px;
  	padding: 10px 25px;
  	margin: 4px;
  	width: max-content;
  	font-size: 14px;
  	max-width: 100%;
  	word-wrap: break-word;
  	white-space: pre-wrap;
  }
</style>

<style lang="stylus">
  .q-carousel {
  	overflow: hidden;

  	& .VueCarousel {
  		position: relative;
  		padding: 0 50px;
  	}

  	& .VueCarousel-wrapper {
  		overflow: visible;
  	}

  	& .VueCarousel-dot-container {
  		margin-bottom: 1px !important;
  		margin-top: 0 !important;
  	}

  	& .VueCarousel-navigation-prev {
  		left: 0;
  	}

  	& .VueCarousel-navigation-next {
  		right: 0;
  	}

  	& .VueCarousel-navigation-prev,
  	& .VueCarousel-navigation-next {
  		opacity: 0;
  		top: 0;
  		transform: none;
  		padding: 0 !important;
  		height: calc(100% - 50px);
  		outline: none !important;
  		background-color: rgba(120, 120, 120, 0.25);
  		width: 35px;
  		color: white;

  		&:hover {
  			opacity: 1;
  		}
  	}

  	& .VueCarousel-navigation--disabled {
  		display: none
  	}
  }
</style>
